* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

/* body {
    
} */
/* Header */
#header {
    background-image: url('/public/img/heroo.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 1080px; */
    /* z-index: 11; */
}
.hero {
    position: relative;
    top: 160px;
    color: #fff;
    /* background: #000; */
}

.text {
    height: 100%;
    display: flex;
    align-items: center;
    /* background: #000; */
}

.text1 h1{
   font-size: 4.3em; 
   font-weight: 700;
   
}
.text1 p{
    margin-top: 30px;
    font-size: 1.4em;
    letter-spacing: normal;
    font-weight: 500;
}

.text1 .btn {
    background-color: #E74040;
    padding: 10px 20px;
    border-radius: 100px;
    color: #fff;
    font-weight: 700;
}

.text1 .btn2{
    margin-left: 7px;
}

.text1 .btn:hover {
    background-color: #fff;
    color: #E74040;
    transition: 0.5s ease-in-out;
}
/* ---------- */

/* INFO BEASISWA */
#info-beasiswa{
    padding: 100px 0;
    text-align: center;
    background-color: #252B42;
}

#info-beasiswa h1, #info-beasiswa .paragraph{
    color: #fff;
}

#info-beasiswa h1{
    font-size: 3em;
    font-weight: 700;
    letter-spacing: 0.1rem;
}

#info-beasiswa .paragraph{
    width: 80%;
    margin: auto;
    margin-top: 25px;
    font-weight: 500;
}

/* beasiswa */
.beasiswa {
    margin-top: 60px;
}

.box-beasiswa {
    width: 100%;
    padding: 50px 0;
    background-color: rgb(255, 255, 255);
    border-radius: 65px;
}

.box-beasiswa.last{
    height: 400px;
    background-color: #2DC071;
    border: 1px solid #275384;
}

.box-beasiswa.last h3 {
    color: #252B42;

}

.p-beasiswa {
    font-size: 1.2em;
    margin-bottom: 30px;
    font-weight: 700;
    color: #737373;   
}

.p-beasiswa.last{
    color: #fff;
}

.box-beasiswa h3 {
    font-size: 4em;
    font-weight: 700;
    color: #252B42;
}

.jml-beasiswa {
    margin-top: 20px;
}

.jml-beasiswa .rp{
    font-size: 1.4em;
    line-height: 10px;
    vertical-align: text-top;
    font-weight: 700;
    color: #1C2172;
}
.jml-beasiswa .nominal{
    font-size: 2.5em;
    font-weight: 700;
    color: #1C2172;
}

.jml-beasiswa .orang{
    font-size: 1.5em;
    font-weight: 700;
    color: #1C2172;
}

.jml-beasiswa .nominal.last, .jml-beasiswa .rp.last {
    color: #fff;
}

.video-ihms {
    /* width: 100%; */
    border-radius: 25px;
    /* background: #fff;
    padding: 20px;
    border-radius: 65px; */
}
/* ------------ */

/* Tahapan */
#tahapan {
    padding: 100px 0;
}
.cara-daftar{
    width: 400px;
    margin: auto;
}

.cara-daftar h2 {
    font-size: 2.5em;
    font-weight: 700;
    color: #252B42;
}

.cara-daftar p {
    font-weight: 500;
}

.cara-daftar a {
    text-decoration: none;
}

.daftar .box {
    border: 1px solid #B9DEFF;
    width: 370px;
    border-radius: 8px;
    margin: auto;
}
.box .header{
    background: #1C2172;
    border-radius: 8px 8px 0 0;
    text-align: center;
    padding: 20px 0;
    color: #fff;
}
.header h4{
    font-size: 1.5em;
    font-weight: 700;
}

.body {
    padding: 20px 5px;
}
.body ol li {
    /* list-style: none; */
    font-weight: 500;
}

.mid-btn {
    /* background: #000; */
    padding: 80px 0;
    text-align: center;
}
.mid-btn .btn {
    background-color: #E74040;
    border-radius: 50px;
    padding: 13px 40px;
    color: #fff;
    font-weight: 700;
    transition: 0.3s ease-in-out;
}

.mid-btn .btn:hover {
    background-color: #fff;
    border:1px solid #E74040;
    color: #E74040;
    transition: 0.4s ease-in-out;
}
/* ------ */

/* Learn More */
#learn-more {
    padding: 50px;
    background-size: cover;
    background-position: top center;
    display: flex;
    flex-direction: column;
}

#learn-more h3 {
    color: #fff;
    margin: auto 0;
    font-size: 2.7em;
    font-weight: 700;
    margin-bottom: 25px;
    margin-right: auto;
}

#learn-more .btn {
    padding: 10px;
    color: #fff;
    width: 200px;
    background: #E74040;
    border-radius: 50px;
    font-weight: 700;
}

#learn-more .btn:hover{
    color: #E74040;
    background: #fff;
    transition: 0.2s ease-in;
}
/* --------- */

/* Footer */
#footer {
    padding: 50px 0;
}
#footer .title1, #footer .title2 {
    font-size: 2em;
    font-weight: 700;
    color: #252B42;
}

#footer a {
    text-decoration: none;
    color: #737373;
    font-weight: 500;
}

#footer a:hover {
    color: #000000;
}

.sosmed {
    margin-top: 15px;
}

.sosmed i {
    margin-right: 20px;
    font-size: 2em;
    color: #1C2172;
}

.sosmed i:hover {
    color: #000000;
    transition: 0.3s ease-in-out;
}

.ihms p {
    font-weight: 700;
}

.copyright {
    padding: 20px 0;
    text-align: center;
    background-color: #FAFAFA;
}

.copyright p {
    font-weight: 700;
    color: #737373;
    margin: auto 0;
}

/* ----- */

@media (width < 769px){
    #header {
        height: 1580px;
    }

    .hero {
        top: 200px;
    }

    .text1 h1{
        font-size: 2.4em; 
     }
     .text1 p{
        font-size: 0.9em;
        letter-spacing: normal;
        margin-top: 0px;
     }
     .text1 a{
        text-decoration: none;
     }
     .text1 .btn{
        display: block;
        margin-bottom: 0px;
        width: 246px;
     }

     #info-beasiswa h1{
        font-size: 2.7em;
        font-weight: 700;
    }

    .box-beasiswa{
        padding: 60px 0;
        width: 300px;
        margin: auto;
        margin-bottom: 30px;
    }

    .paragraph {
        font-size: 1.1em;
    }

    .p-beasiswa {
        margin-bottom: 10px;
    }

    .jml-beasiswa {
        margin-top: 10px;
    }

    .video-ihms {
        /* width: 100%; */
        height: 500px;
    }

    .cara-daftar h2 {
        margin-bottom: 20px;
    }

    .cara-daftar {
        width: 100%;
        margin-bottom: 50px;
        text-align: center;
    }

    .mid-btn {
        padding: 70px 0;
    }

    .daftar .box {
        width: 100%;
    }

    #learn-more h3 {
        font-size: 2.3em;
    }

    #footer .title2 {
        margin-top: 50px;
    }

    #footer p {
        color: #737373;
    }

    .contact, .ihms {
        text-align: center;
    }


}
@media (width > 767px){

    #header {
        height: 900px;
    }
    .hero {
        top: 140px;
    }
    .text1 h1{
        font-size: 2.7em; 
     }
     .text1 p{
        font-size: 0.9em;
        letter-spacing: normal;
     }
     .box-beasiswa {
        width: 100%;
        margin-bottom: 20px;
    }

    .video-ihms {
        margin-top: 50px;
    }

    .cara-daftar {
        margin-bottom: 50px;
    }

    .cara-daftar h2 {
        text-align: center;
        margin-bottom: 20px;
    }
    .cara-daftar p {
        text-align: center;
    }
    .daftar .box {
        width: 470px;
    }

    .contact, .ihms {
        text-align: left;
    }

    #footer .title2 {
        margin-top: 0px;
    }
}
@media (width > 1023px){
    .box-beasiswa {
        width: 100%;
        padding: 30px 0;
    }
    .box-beasiswa h3 {
        font-size: 3em;
    }
    .video-ihms{
        width: 350px;
    }
    .jml-beasiswa .rp {
        font-size: 1em;
    }
    .jml-beasiswa .nominal {
        font-size: 2em;
    }
    .contact .title1, .ihms .title2{
        margin-bottom: 20px;
    }
    .contact .sosmed{
        margin-top: 28px;
    }
    .ihms{
        width: 360px;
    }
}

@media (width > 1439px){
    #header {
        height: 850px;
    }
    .hero {
        top: 210px;
    }
    .text1 h1{
        font-size: 3.5em; 
    }
     .text1 p{
        font-size: 1.3em;
        letter-spacing: normal;
    }
    .beasiswa{
        margin-top: 80px;
    }

    .box-beasiswa{
        padding: 50px 0;
    }
    .p-beasiswa {
        font-size: 1.5em;
    }
    .box-beasiswa h3 {
        font-size: 4.5em;
    }
    /* .box-beasiswa .nominal {
        font-size: 2;
    } */

    /* .video-ihms {
        height:90%;
    } */


}